// src/components/UploaderPage.jsx

import React, { useState } from 'react';
import axios from 'axios';
import baseURL from "../config";
import Navbar from "./Navbar";

export default function UploaderPage() {
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            setUploadStatus('Please select a file first.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${baseURL}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setUploadStatus('File uploaded successfully.');
        } catch (error) {
            setUploadStatus('Failed to upload the file.');
        }
    };

    return (
        <div>
            <Navbar></Navbar>


        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-2xl font-bold mb-4">Upload Excel File</h1>
            <input type="file" accept=".xlsx" onChange={handleFileChange} className="mb-4" />
            <button
                onClick={handleUpload}
                className="px-4 py-2 bg-blue-500 text-white rounded"
            >
                Upload
            </button>
            {uploadStatus && <p className="mt-4 text-red-500">{uploadStatus}</p>}
        </div>
        </div>
    );
}
