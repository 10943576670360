import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import baseURL from "../config";
import Navbar from "./Navbar";
import {toast, ToastContainer} from "react-toastify";
import {validateAdmin, validateOperator} from "./Beranda";

const institutions = ['Sdi', 'Mts', 'MA'];
const daysOfWeek = ['Sabtu', 'Ahad', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat'];
const daysMapping = {
    'Sabtu': 'sabtu',
    'Ahad': 'ahad',
    'Senin': 'senin',
    'Selasa': 'selasa',
    'Rabu': 'rabu',
    'Kamis': 'kamis',
    'Jumat': 'jumat'
};



export const DataGuruPage = () => {
    const [selectedInstitution, setSelectedInstitution] = useState('');
    const [adminData, setAdminData] = useState([]);
    const queryClient = useQueryClient();

    const { data, refetch } = useQuery(['adminData', selectedInstitution], async () => {
        if (!selectedInstitution) return null;
        const response = await axios.get(`${baseURL}/get-guru-lembaga/${selectedInstitution.toLowerCase()}`);
        return response.data;
    }, {
        enabled: false,
        onSuccess: (data) => {
            if (data) {
                const sortedData = [...data].sort((a, b) =>
                    a.nama_admin.localeCompare(b.nama_admin, 'id', { sensitivity: 'base' })
                );
                setAdminData(sortedData);
            }
        }
    });

    const updateMutation = useMutation((updatedData) =>

            axios.patch(`${baseURL}/update-banyak-admin`, updatedData),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['adminData', selectedInstitution]);
                refetch();
                console.log("Sukseeeeees!!!!")
                toast.success("Berhasil!")
            }
        }
    );

    const handleProcess = () => {
        if (!selectedInstitution) {
            alert("Anda Belum Memilih");
            console.log("User attempted to process without selecting an institution");
        } else {
            refetch();
        }
    };

    const handleCheckboxChange = (adminIndex, day) => {
        const newAdminData = [...adminData];
        const mappedDay = daysMapping[day];
        newAdminData[adminIndex].atribut_mesin.jadwal_masuk[mappedDay] =
            !newAdminData[adminIndex].atribut_mesin.jadwal_masuk[mappedDay];
        setAdminData(newAdminData);
    };

    const handleSave = () => {
        console.log(adminData)
        if (validateOperator()) updateMutation.mutate(adminData);
        else toast.error("Hanya Admin yang dapat mengubah Jadwal")
    };

    return (
        <div>
            <Navbar></Navbar>
            <ToastContainer></ToastContainer>
        <div className="container mx-auto pt-2 pl-2 pb-2">
            <div className="mb-4">
                <select
                    className="border p-2 mr-2 bg-white"
                    value={selectedInstitution}
                    onChange={(e) => setSelectedInstitution(e.target.value)}
                >
                    <option value="">Pilih Lembaga</option>
                    {institutions.map((inst) => (
                        <option key={inst} value={inst}>{inst}</option>
                    ))}
                </select>
                <button
                    className="bg-blue-500 text-white p-2 rounded"
                    onClick={handleProcess}
                >
                    Proses
                </button>
            </div>

            {adminData.length > 0 && (
                <>
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse border">
                            <thead>
                            <tr className={'bg-yellow-400'}>
                                <th className="border p-2 ">Nama Admin</th>
                                <th className="border p-2">HP</th>
                                {daysOfWeek.map((day) => (
                                    <th key={day} className="border p-2">{day}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {adminData.map((admin, index) => (
                                <tr key={admin.nis} className={`${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}`}>
                                    <td className={`border p-2 whitespace-nowrap sticky left-0 ${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}`}>{admin.nama_admin}</td>
                                    <td className="border p-2">{admin.nis}</td>
                                    {daysOfWeek.map((day) => (
                                        <td key={day} className="border p-2 text-center">
                                            <input
                                                type="checkbox"
                                                checked={admin.atribut_mesin.jadwal_masuk[daysMapping[day]]}
                                                onChange={() => handleCheckboxChange(index, day)}
                                                className="w-5 h-5 cursor-pointer"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <button
                        className="mt-4 bg-green-500 text-white p-2 rounded"
                        onClick={handleSave}
                    >
                        Simpan
                    </button>
                </>
            )}
        </div>
        </div>
    );
}