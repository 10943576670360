import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from "./Navbar";
import {toast, ToastContainer} from "react-toastify";


export function IotController() {
    const [belModalVisible, setBelModalVisible] = useState(false);
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [lampuAulaModalVisible, setLampuAulaModalVisible] = useState(false);
    const [endpoint, setEndpoint] = useState('');

    useEffect(() => {
        // Fetch the IoT endpoint URL when the page loads
        const fetchEndpoint = async () => {
            try {
                const response = await axios.get('https://musama.net/api/v1/get-url');
                setEndpoint(response.data.url);
                console.log(response.data.url)
            } catch (error) {
                console.error('Error fetching IoT endpoint URL:', error);
            }
        };

        fetchEndpoint();
    }, []);

    const handleBelConfirm = async () => {
        try {
            const response = await axios.post(`${endpoint}/send`, { mode: 'bel', id: 0 });
            if (response.status === 200) toast.success("Berhasil..!",{autoClose:1000});
            setBelModalVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleResetConfirm = async (selectedId) => {
        try {
            const response = await axios.post(`${endpoint}/send`, { mode: 'reset', id: selectedId });
            if (response.status === 200) toast.success("Berhasil!",{autoClose:1000});
            setResetModalVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleLampuAulaConfirm = async (mode) => {
        try {
            const response = await axios.post(`${endpoint}/send`, { mode, id: 0 });
            console.log(mode)
            if (response.status === 200) toast.success("Sukses!",{autoClose:1000});
            setLampuAulaModalVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>

            <Navbar></Navbar>
            <ToastContainer></ToastContainer>
        <div className="p-8">
            <h1 className="text-2xl font-bold mb-8">Pusat Kendali Musama</h1>

            <button
                className="bg-blue-600 text-white px-6 py-2 text-xl mr-3 rounded-lg mb-4"
                onClick={() => setBelModalVisible(true)}
                disabled={!endpoint}
            >
                Bunyikan Bel
            </button>

            <button
                className="bg-red-700 text-white px-6 py-2 text-xl mr-3 rounded-lg mb-4"
                onClick={() => setResetModalVisible(true)}
                disabled={!endpoint}
            >
                Reset Mesin
            </button>

            <button
                className="bg-teal-600 text-white px-6 py-2 text-xl mr-3 rounded-lg"
                onClick={() => setLampuAulaModalVisible(true)}
                disabled={!endpoint}
            >
                Listrik Aula
            </button>

            {!endpoint && (
                <p className="text-red-500 mt-4">
                    Fetching IoT endpoint URL, please wait...
                </p>
            )}

            <BelModal
                show={belModalVisible}
                onClose={() => setBelModalVisible(false)}
                onConfirm={handleBelConfirm}
            />

            <ResetModal
                show={resetModalVisible}
                onClose={() => setResetModalVisible(false)}
                onConfirm={handleResetConfirm}
            />

            <LampuAulaModal
                show={lampuAulaModalVisible}
                onClose={() => setLampuAulaModalVisible(false)}
                onConfirm={handleLampuAulaConfirm}
            />
        </div>
        </div>

    );
}

const LampuAulaModal = ({ show, onClose, onConfirm }) => {
    const [mode, setMode] = useState('Nyala');

    useEffect(() => {
        if (show) {
            // Reset mode to default "Nyala" whenever the modal is opened
            setMode('Nyala');
        }
    }, [show]);


    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-lg font-semibold mb-4">Lampu Aula</h2>
                <div>
                    <label className="block mb-2">
                        <input
                            type="radio"
                            name="lampuMode"
                            value="Nyala"
                            className="mr-2"
                            defaultChecked
                            onChange={() => setMode('Nyala')}
                        />
                        Nyala
                    </label>
                    <label className="block mb-2">
                        <input
                            type="radio"
                            name="lampuMode"
                            value="Mati"
                            className="mr-2"
                            onChange={() => setMode('Mati')}
                        />
                        Mati
                    </label>
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2"
                        onClick={() => onConfirm(mode)}
                    >
                        Ya
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Batal
                    </button>
                </div>
            </div>
        </div>
    );
};


const ResetModal = ({ show, onClose, onConfirm }) => {
    const [selectedId, setSelectedId] = useState(null);

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-lg font-semibold mb-4">Pilih ID untuk Reset</h2>
                <div>
                    {['100', '101','200','300', '301','305', '400', '500', '600', '700', '701', '702'].map(id => (
                        <label key={id} className="block mb-2 text-xl">
                            <input
                                type="radio"
                                name="resetId"
                                value={id}
                                className="mr-4"
                                onChange={(e) => setSelectedId(e.target.value)}
                            />
                            {id}
                        </label>
                    ))}
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                        onClick={() => onConfirm(selectedId)}
                    >
                        Ya
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Batal
                    </button>
                </div>
            </div>
        </div>
    );
};

const BelModal = ({ show, onClose, onConfirm }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-lg font-semibold mb-4">Konfirmasi</h2>
                <p>Apakah anda yakin?</p>
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                        onClick={onConfirm}
                    >
                        Ya
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Batal
                    </button>
                </div>
            </div>
        </div>
    );
};


